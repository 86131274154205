<template>
  <!-- 重置密码的弹框 -->
  <el-dialog title="重置密码" :visible="visible" @open="open" width="400px">
    <el-form
      label-position="right"
      label-width="80px"
      :rules="rules"
      ref="passwordData"
      :model="passwordData"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="账号">
            <el-input
              placeholder="请输入内容"
              v-model="passwordData.username"
              clearable
              disabled
              class="my-input"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="真实姓名">
            <el-input
              placeholder="请输入内容"
              v-model="passwordData.fullName"
              clearable
              disabled
              class="my-input"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="密码" prop="password">
            <el-input
              placeholder="请输入内容"
              v-model="passwordData.password"
              show-password
              class="my-input"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="确认密码" prop="password2">
            <el-input
              placeholder="请再次输入密码"
              v-model="passwordData.password2"
              show-password
              class="my-input"
              clearable
              @change="confirmPassword"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel" size="large">取 消</el-button>
      <el-button
        type="primary"
        size="large"
        @click="confirmPassword"
        :loading="loading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { editpassword } from "@/api";
export default {
  name: "ResetPassword",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    /* 密码自定义校验规则 */
    let Password = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入密码"));
      } else {
        let passwordReg = /^(?![^a-zA-Z]+$)(?!\D+$).{6,10}$/;
        if (passwordReg.test(value)) {
          callback();
        } else {
          callback(new Error("密码需要包含字母和数字，长度6 ~ 10位"));
        }
      }
    };

    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordData.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      passwordData: {
        username: "",
        fullName: "",
        password: "",
        password2: "",
      },
      rules: {
        password: [
          { required: true, validator: Password, trigger: ["blur", "change"] },
        ],
        password2: [
          {
            required: true,
            validator: validatePass2,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },

  created() {},

  methods: {
    open() {
      var page = JSON.parse(sessionStorage.getItem("user"));
      this.passwordData.username = page.username;
      this.passwordData.fullName = page.fullName;
    },
    // 取消
    cancel() {
      this.$emit("update:visible", false);
    },
    // 确认修改密码
    confirmPassword() {
      this.$refs.passwordData.validate((valid) => {
        if (valid) {
          this.loading = true;
          editpassword(this.passwordData)
            .then((res) => {
              this.loading = false;
              if (res.data.code == "000000") {
                this.cancel();
                this.$alert("密码修改成功，请重新登录", "提示", {
                  confirmButtonText: "确定",
                  type: "success",
                  callback: (action) => {
                    this.$store.commit("SET_TOKEN");
                    sessionStorage.clear();
                    location.reload();
                    this.$destroy();
                  },
                });
              } else {
                this.$message.error("修改密码异常");
              }
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
